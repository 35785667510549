const titleCase = (str) => {
  return str.split(" ").map(([firstChar,...rest]) => firstChar.toUpperCase() + rest.join("").toLowerCase()).join(" ")
}

const truncate = (str, length, ellipsis = true) => {
  if (str.length <= length) {
    return str;
  }
  
  if (ellipsis) {
    return str.slice(0, length).trim().concat('...');
  } else {
    return str.slice(0, length).trim();
  }
}

const redactPhoneNumber = (str) => {
  return str ? str.length > 5 ? str.slice(0, 2).concat('••••••').concat(str.slice(-4)) : '' : '';
}

const sanitize = (str) => {
  if (!str) return str;

  return str.replace(/[^a-zA-Z0-9\s\'#_\.\-,]/g, '');
}

const sanitizeObjectValues = (obj) => {
  if (!obj) return obj;

  for (const key in obj) {
    if (obj[key]) {
      obj[key] = sanitize(obj[key]);
    }
  }

  return obj;
}

export default {
  titleCase,
  truncate,
  redactPhoneNumber,
  sanitize,
  sanitizeObjectValues,
}