import { isNil } from 'lodash';
import Currencies from '../constants/Currencies'

const getCurrencies = () => {
  try {
    return Currencies.map((c) => {
      return { label: `${c.name} (${c.symbol})`, value: c.code }
    })
  } catch (e) {
    console.log(e);
    return [];
  }
}

const getCurrencySymbol = (currencyCode = 'PHP') => {
  try {
    return Currencies.find((c) => {
      return c.code === currencyCode.toUpperCase();
    }).symbol;
  } catch (e) {
    console.error(e);
    return '';
  }
}

const isValidCurrency = (currency) => {
  try {
    return Currencies.find((c) => {
      return c.code === currency.toUpperCase();
    }) !== undefined;
  } catch (e) {
    console.error(e);
    return false;
  }
}

const getCurrencyMinimum = (currencyCode = 'PHP') => {
  try {
    return Currencies.find((c) => {
      return c.code === currencyCode.toUpperCase();
    }).minimum;
  } catch (e) {
    console.error(e);
    return 1;
  }
}

const formatAmount = (amount, decimalCount = 2, decimal = '.', thousands = ",") => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '');
  } catch (e) {
    console.log(e);
    return amount;
  }
};

const formatCurrency = (amount, currencyCode = 'PHP') => {
  try {
    if (amount === undefined) amount = 0;
    if (currencyCode === '' || isNil(currencyCode)) return formatAmount(amount);
    const currency = Currencies.find((c) => {
      return c.code === currencyCode.toUpperCase();
    })

    return (currency === '' || isNil(currency)) ? formatAmount(amount) :
      currency.symbol + formatAmount(amount, currency.decimal_digits, currency.separator, currency.delimiter);
  } catch (e) {
    console.log(e);
    return formatAmount(amount);
  }
};

const roundOff = (value, precision = 0) => {
  try {
    if (value === undefined) value = 0;
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  } catch (e) {
    console.log(e);
    return '';
  }
}

export default {
  getCurrencies,
  isValidCurrency,
  getCurrencySymbol,
  getCurrencyMinimum,
  formatAmount,
  formatCurrency,
  roundOff,
};